<template>
  <div>
    <div>
      <p class="mb-1 section-title text-white text-with-shadow">Select Platform </p>
      <b-row class="match-height">
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-row>
              <b-col cols="12" class="text-center">
                <!-- Account Details -->
                <div v-if="googleViewID && googleViewProfile" class="account-details">
                  <div class="d-flex align-items-center">
                    <div class="avatar-section">
                      <b-avatar square class="mb-1" variant="white" size="45"
                        src="@/assets/images/logo/analytics-logo.png">
                      </b-avatar>
                    </div>
                    <div class="account-data text-left ml-1">
                      <b-link :to="`/client/${slug}/settings/google-analytics`">
                        <h3 class="app-title md">Google Analytics</h3>
                      </b-link>
                      <p class="account-name">{{ googleViewProfile }}</p>
                      <p class="manager-account">
                        <span>
                          {{ googleViewID }}
                        </span>
                      </p>
                    </div>
                  </div>

                  <b-button block :to="`/client/${slug}/dashboard/google-analytics`"
                    v-b-tooltip.hover.top="'Google Analytics Dashboard'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary" class="mt-1">
                    Continue
                  </b-button>

                  <b-button :disabled="role === 'VIEWER'" :class="next === 'google-analytics-account-selection' ||
                    !googleViewID
                    ? 'pulse-button'
                    : ''
                    " @click="fetchAnalyticsAccounts" variant="flat-dark">
                    <b-spinner v-if="isFetchingAccounts" small />
                    <span v-if="role === 'VIEWER'"><b-icon class="upDownIcon" icon="dash-circle" /></span> Reselect
                    Account</b-button>
                </div>
                <!-- Account Details -->
                <div v-else>
                  <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/analytics-logo.png">
                  </b-avatar>
                  <div class="truncate">
                    <h3 class="mb-25 font-weight-bolder">Google Analytics</h3>
                    <div v-if="googleViewID && googleViewProfile" class="mt-1">
                      <b-badge variant="dark">{{ googleViewID }}</b-badge> -
                      <b-badge variant="dark">{{ googleViewProfile }}</b-badge>
                    </div>
                    <div class="mt-2">
                      <b-button block @click="googleAnalyticsConnect" v-if="!integrations.google"
                        variant="outline-primary">Connect</b-button>
                      <b-button :disabled="role === 'VIEWER'" :class="next === 'google-analytics-account-selection' ||
                        !googleViewID
                        ? 'pulse-button'
                        : ''
                        " @click="fetchAnalyticsAccounts" v-else variant="primary">
                        <b-spinner v-if="isFetchingAccounts" small />
                        {{
                          googleViewID ? "Reselect Account" : "Select Account"
                        }}</b-button>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- Google Ads -->
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-row>
              <b-col cols="12">
                <!-- <div v-if="googleAdsCustomerID && isProjectEnabled " style="margin-top: -35px; margin-right: -81%; margin-bottom: 20px;"
                  @click="showAddModalProject">
                  <div style="cursor: pointer;" >
                    <b-button size="sm" variant="outline-primary"><feather-icon icon="PlusCircleIcon" color="#200E6B"
                        size="15" style="margin-right: 5px;" />Projects <b-spinner v-if="isFetchComplete" small
                        variant="primary" /></b-button>
                  </div>
                </div> -->
                <!-- <div v-if="googleAdsCustomerID && ! googleAds.isProjectsEnabled" style="margin-top: -40px; margin-right: -65%; margin-bottom: 20px;" @click="showAddModalProject">
                <div style="cursor: pointer;">
                   <b-button  size="xs"
              variant="outline-primary"><feather-icon icon="PlusCircleIcon" color="#200E6B" size="15" style="margin-right: 5px;" />Add Project</b-button> 
                </div>
               </div>
               <div v-if="googleAdsCustomerID && googleAds.isProjectsEnabled" style="margin-top: -40px; margin-right: -73%; margin-bottom: 20px;" @click="openListModal">
                <div style="cursor: pointer;">
                  <b-button  size="xs"
              variant="outline-primary"><feather-icon icon="EyeIcon" color="#200E6B" size="18" /></b-button>
                </div>
               </div> -->
                <!-- Account Details -->
                <div v-if="googleAdsCustomerID" class="account-details">

                  <div class="d-flex align-items-center">
                    <div class="avatar-section">
                      <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/ads-logo.png">
                      </b-avatar>
                    </div>
                    <div class="account-data text-left ml-1">
                      <b-link>
                        <h3 class="app-title md">Google Ads</h3>
                      </b-link>
                      <p class="account-name text-style">
                        {{ googleAds.managerName }}
                      </p>
                      <p class="manager-account">
                        <div v-if="googleAds.manager">
                          <div v-if="googleAds.customerNames.length === 1">
                            <div>
                              <ol>
                                <li class="account-name text-style" v-for="(item, index) in googleAds.customerNames"
                                  :key="index" style="width: 150px;">
                                  {{ item }}
                                </li>
                              </ol>
                            </div>
                          </div>
                          <div v-else-if="showGoogleMore || googleAds.customerNames.length <= maxLength">
                            <div style="display: flex; justify-content:center">
                              <div>
                                <ol>
                                  <li class="account-name text-style" v-for="(item, index) in  googleAds.customerNames"
                                    :key="index" style="width: 150px;">
                                    {{ item }}
                                  </li>
                                </ol>
                              </div>
                              <div style="width:120px">
                                <a href="#" class="ml-3" v-if="googleAds.customerNames.length > 0" @click="toggleGoogleShowMore">See
                                  Less<feather-icon icon="ChevronsUpIcon" /></a>
                              </div>
    
                            </div>
                          </div>
                          <div v-else>
                            <div style="display: flex; justify-content:center">
                              <div>
                                <ol>
                                  <li class="account-name text-style"
                                    v-for="(item, index) in truncatedGoogleData" :key="index" style="width: 130px;">
                                    {{ item }}
                                  </li>
                                </ol>
                              </div>
                              <div>
                              </div>
                              <a href="#" @click="toggleGoogleShowMore" style="width:100px">See More<feather-icon
                                  icon="ChevronsDownIcon" /></a>
                            </div>
                            <!-- <ol>
                              <li class="account-name text-style" v-for="(item, index) in truncatedData" :key="index">
                                {{ item }}''
                              </li>
                            </ol>
                            <a href="#" class="ml-3" @click="toggleShowMore">See more <feather-icon icon="ChevronsDownIcon" /></a> -->
                          </div>
                          <!-- {{ googleAds.customerName }} -->
                      
                          <!-- <div v-if="showMore || googleAds.customerName.length <= maxLength"> -->
                          <!-- <ol>
                              <li class="account-name text-style" v-for="(item, index) in googleAds.customerName"
                                :key="index">
                                {{ item }}
                              </li>
                            </ol>
                          </div>
                          <div v-else>
                            <ol>
                              <li class="account-name text-style" v-for="(item, index) in truncatedGoogleAdsData"
                                :key="index">
                                {{ item }}
                              </li>
                            </ol>
                            <a href="#" class="ml-3" @click="toggleShowMore">See more</a>
                          </div>
                          <p class="manager-account"></p> -->
                        </div>
                        <span v-else> NOT_MCC </span>
                      <p v-if="googleAdError" class="text-danger mt-1">
                        Please try again.
                      </p>
                      </p>
                    </div>
                  </div>
                  <div v-if="integrations.googleAds">
                    <b-button block :to="`/client/${slug}/dashboard/google-ads`"
                    v-b-tooltip.hover.top="'View Google Ads Data'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary" class="mt-1">
                    Continue
                  </b-button>

                  <b-button :disabled="role === 'VIEWER'" :class="next === 'google-ads-customer-selection' ||
                    !googleAdsCustomerID
                    ? 'google-pulse-button'
                    : ''
                    " @click="selectGoogleAdsCustomers" variant="flat-dark-outline">
                    <b-spinner v-if="isFetchingAdsCustomers" small />
                    <span class="customize-text">
                      <span v-if="role === 'VIEWER'"><b-icon class="upDownIcon" icon="dash-circle" /></span> Reselect
                      Ads Account(s)</span></b-button>
                    </div>
                    <div v-else>
                      <b-button block @click="googleAdsConnect" v-if="!integrations.gmb"
                      variant="outline-primary">Connect</b-button>
                      </div>
              
                </div>
                <!-- Account Details -->
                <div v-else>
                  <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/ads-logo.png">
                  </b-avatar>
                  <div class="truncate">
                    <h3 class="mb-25 font-weight-bolder">Google Ads</h3>
                    <!-- buttons -->
                    <div class="mt-2">
                      <b-button block @click="googleAdsConnect('google-ads')" v-if="!integrations.googleAds"
                        variant="outline-primary">Connect</b-button>
                      <div v-if="!googleAdError">
                        <b-button :class="next === 'google-ads-customer-selection' ||
                          !googleAdsCustomerID
                          ? 'google-pulse-button'
                          : ''
                          " @click="selectGoogleAdsCustomers" v-if="integrations.googleAds" variant="primary">
                          <b-spinner v-if="isFetchingAdsCustomers" small />Select
                          Customer</b-button>
                      </div>
                      <div>
                        <b-button block @click="googleAdsConnect('google-ads')" v-if="googleAdError"
                          variant="outline-primary">Connect</b-button>
                        <p v-if="googleAdError" class="text-danger mt-1">
                          Please try again.
                        </p>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- Google my business -->
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-row>
              <b-col cols="12">
                <!-- Account Details -->
                <div v-if="accountID.length" class="account-details">
                  <div class="d-flex align-items-center">
                    <div class="avatar-section">
                      <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/gmb.jpeg">
                      </b-avatar>
                    </div>
                    <div class="account-data text-left">
                      <b-link>
                        <h3 class="app-title md ml-2">GMB</h3>
                      </b-link>
                      <div v-if="showMore || gmbName.length <= maxLength">
                        <div style="display: flex; justify-content:center">
                          <div>
                            <ol>
                              <li class="account-name text-style" v-for="(item, index) in gmbName" :key="index">
                                {{ item }}
                              </li>
                            </ol>
                          </div>
                          <div>
                          </div>
                          <a href="#" class="ml-3" @click="toggleShowMore" style="font-size: 13px; width:100px">See
                            Less<feather-icon icon="ChevronsUpIcon" /></a>
                        </div>
                      </div>
                      <div v-else>
                        <div style="display: flex; justify-content:center">
                          <div>
                            <ol>
                              <li class="account-name text-style" v-for="(item, index) in truncatedData" :key="index">
                                {{ item }}
                              </li>
                            </ol>
                          </div>
                          <div>
                          </div>
                          <a href="#" @click="toggleShowMore" style="font-size: 13px; width:100px">See More<feather-icon
                              icon="ChevronsDownIcon" /></a>
                        </div>
                        <!-- <ol>
                          <li class="account-name text-style" v-for="(item, index) in truncatedData" :key="index">
                            {{ item }}''
                          </li>
                        </ol>
                        <a href="#" class="ml-3" @click="toggleShowMore">See more <feather-icon icon="ChevronsDownIcon" /></a> -->
                      </div>
                      <p class="manager-account"></p>
                    </div>
                  </div>
                  <div v-if="integrations.gmb">
                    <b-button block :to="`/gmb/${slug}`" v-b-tooltip.hover.top="'Google My Business'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1">
                    Continue
                  </b-button>

                  <b-button :disabled="role === 'VIEWER'" @click="selectGMBCustomers" variant="flat-dark-outline">
                    <b-spinner v-if="isFetchingGMBAccounts" small />
                    <span class="customize-text"> <span v-if="role === 'VIEWER'"> <b-icon class="upDownIcon"
                          icon="dash-circle" /></span> Reselect Location Group(s)</span></b-button>
                  </div>
                  <div v-else>
                    <b-button block @click="gmbConnect" v-if="!integrations.gmb"
                    variant="outline-primary">Connect</b-button>
                  </div>
                 
                </div>
                <!-- Account Details -->
                <div v-else>
                  <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/gmb.jpeg">
                  </b-avatar>
                  <div class="truncate">
                    <b-link>
                      <h3 class="app-title md">GMB</h3>
                    </b-link>
                    <p class="account-name" v-if="accountID.length">
                      {{ gmbName }}
                    </p>
                    <!-- buttons -->
                    <div class="mt-2">
                      <b-button block @click="gmbConnect" v-if="!integrations.gmb"
                        variant="outline-primary">Connect</b-button>
                      <b-button v-else :disabled="role === 'VIEWER'" :class="next === 'google-ads-customer-selection' ||
                        !integrations.gmb
                        ? 'gmb-pulse-button'
                        : ''
                        " @click="selectGMBCustomers" variant="primary">
                        <b-spinner v-if="isFetchingGMBAccounts" small />
                        Select Location Group(s)</b-button>

                      <b-button v-else block :to="`/gmb/${slug}`" v-b-tooltip.hover.top="'Google My Business'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1">
                        Continue
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <!-- Amazon Ads -->
        <b-col>
          <!-- TODO -->
          <div v-if="amazonProfileName.length">
            <b-card variant="primary" class="text-center py-2 px-2">
              <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/amazon.png">
              </b-avatar>
              <div class="truncate">
                <h3 class="mb-25 font-weight-bolder">Amazon</h3>
                <div style="font-size: 10px;" class=" text-style" v-for="(item, index) in amazonProfileName"
                  :key="index">
                  <p style="font-size: 12px; text-align:center">{{ item }}</p>
                </div>
                <div class="mt-2">
                  <b-button v-if="integrations.amazon" variant="primary" :to="`/client/${slug}/dashboard/amazon-ads`"
                    v-b-tooltip.hover.top="'Amazon Ads Dashboard'">See Amazon Profile Data</b-button>
                  <b-button :disabled="role === 'VIEWER'" @click="selectAmazonProfile" variant="flat-dark-outline">
                    <b-spinner v-if="isAmazonProfileLoading" small />
                    <span class="customize-text"> <span v-if="role === 'VIEWER'"><b-icon class="upDownIcon"
                          icon="dash-circle" /></span>Reselect Amazon Profile(s)</span></b-button>
                </div>
              </div>
            </b-card>
          </div>
          <div v-else>
            <b-card variant="primary" class="text-center py-2 px-2">
              <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/amazon.png">
              </b-avatar>
              <div class="truncate">
                <h3 class="mb-25 font-weight-bolder">Amazon</h3>
                <div class="mt-2">
                  <b-button block @click="amazonConnect" v-if="!integrations.amazon"
                    variant="outline-primary">Connect</b-button>
                  <b-button v-if="integrations.amazon" :disabled="role === 'VIEWER'" variant="primary"
                    @click="selectAmazonProfile">
                    <b-spinner v-if="isAmazonProfileLoading" small />
                    <span>Select Profile</span></b-button>
                </div>
              </div>
            </b-card>
          </div>

        </b-col>
        <!-- Meta Ads -->
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-row>
              <b-col cols="12">
                <!-- Account Details -->
              <div v-if="facebookAccountName.length > 0" class="account-details">
                <div>
                  <div class="d-flex align-items-center">
                    <div class="avatar-section">
                      <b-avatar square class="mb-1" variant="white" size="45"
                        src="@/assets/images/logo/meta.png">
                      </b-avatar>
                    </div>
                    <div class="account-data text-left">
                      <b-link>
                        <h3 class="app-title md ml-2">Meta</h3>
                      </b-link>
                      <div v-if="facebookAccountName.length === 1">
                        <div>
                          <ol>
                            <li class="account-name text-style" v-for="(item, index) in facebookAccountName"
                              :key="index" style="width: 150px;">
                              {{ item }}
                            </li>
                          </ol>
                        </div>
                      </div>
                      <div v-else-if="showMetaMore || facebookAccountName.length <= maxLength">
                        <div style="display: flex; justify-content:center">
                          <div>
                            <ol>
                              <li class="account-name text-style" v-for="(item, index) in facebookAccountName"
                                :key="index" style="width: 150px;">
                                {{ item }}
                              </li>
                            </ol>
                          </div>
                          <div style="width:120px">
                            <a href="#" class="ml-3" @click="toggleMetaShowMore">See
                              Less<feather-icon icon="ChevronsUpIcon" /></a>
                          </div>
  
                        </div>
                      </div>
                      <div v-else>
                        <div style="display: flex; justify-content:center">
                          <div>
                            <ol>
                              <li class="account-name text-style"
                                v-for="(item, index) in truncatedFacebookData" :key="index" style="width: 130px;">
                                {{ item }}
                              </li>
                            </ol>
                          </div>
                          <div>
                          </div>
                          <a href="#" @click="toggleMetaShowMore" style="width:100px">See More<feather-icon
                              icon="ChevronsDownIcon" /></a>
                        </div>
                        <!-- <ol>
                          <li class="account-name text-style" v-for="(item, index) in truncatedData" :key="index">
                            {{ item }}''
                          </li>
                        </ol>
                        <a href="#" class="ml-3" @click="toggleShowMore">See more <feather-icon icon="ChevronsDownIcon" /></a> -->
                      </div>
                      <p class="manager-account"></p>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div  v-if="integrations.facebook">
                      <b-button block v-if="integrations.facebook" variant="primary"
                      :to="`/client/${slug}/dashboard/meta-ads`" v-b-tooltip.hover.top="'View Meta Ads Data'">
                      Continue</b-button>
                    <b-button :disabled="role === 'VIEWER'" @click="selectFacebookAdsAccount"
                      variant="flat-dark-outline">
                      <b-spinner v-if="isFetchingFacebookAccounts" small />
                      <span class="customize-text"> <span v-if="role === 'VIEWER'"><b-icon class="upDownIcon"
                            icon="dash-circle" /></span>Reselect Ads Account(s)</span></b-button>
                    </div>
                    <div v-else>
                      <b-button block @click="facebookConnect" v-if="!integrations.amazon"
                      variant="outline-primary">Connect</b-button>
                    </div>
                   
                  </div>
                </div>
              
                <!-- <b-button block :to="`/stores/${slug}`" v-b-tooltip.hover.top="'Google My Business'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1">
                  Continue
                </b-button>

                <b-button :disabled="role === 'VIEWER'" @click="selectGMBCustomers" variant="flat-dark-outline">
                  <b-spinner v-if="isFetchingGMBAccounts" small />
                  <span class="customize-text"> <span v-if="role === 'VIEWER'"> <b-icon class="upDownIcon"
                        icon="dash-circle" /></span> Reselect Location Group(s)</span></b-button> -->
              </div>
                <!-- Account Details -->
                <div v-else>
                  <b-avatar square class="mb-1" variant="white" size="45"
                    src="@/assets/images/logo/meta.png">
                  </b-avatar>
                  <div class="truncate">
                    <b-link>
                      <h3 class="app-title md">Meta Ads</h3>
                    </b-link>
                    <div style="font-size: 10px;" class=" text-style" v-for="(item, index) in facebookAccountName"
                      :key="index">
                      <p style="font-size: 12px; text-align:center">{{ item }}</p>
                    </div>
                    <!-- buttons -->
                    <div class="mt-2">
                      <b-button block @click="facebookConnect" v-if="!integrations.facebook"
                        variant="outline-primary">Connect</b-button>
                      <b-button v-if="integrations.facebook" :disabled="role === 'VIEWER'"
                        @click="selectFacebookAdsAccount" variant="primary" :class="next === 'google-analytics-account-selection' ||
                          !googleViewID
                          ? 'pulse-button'
                          : ''
                          ">
                        <b-spinner v-if="isFetchingFacebookAccounts" small />
                        <span v-if="integrations.facebook">Select Ads Account(s)</span></b-button>

                      <!-- <b-button v-else block :to="`/stores/${slug}`" v-b-tooltip.hover.top="'Google My Business'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1">
                        Continue
                      </b-button> -->
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- <b-col>
          <div v-if="facebookAccountName.length">
            <b-card variant="primary" class="text-center py-2 px-2">
              <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/meta.png">
              </b-avatar>
              <div class="truncate">
                <h3 class="mb-25 font-weight-bolder">Meta Ads</h3>
                <div v-if="showMore || facebookAccountName.length <= maxLength">
                  <div style="display: flex; justify-content:center">
                    <div>
                      <ol>
                        <li class="account-name text-style" v-for="(item, index) in facebookAccountName" :key="index">
                          {{ item }}
                        </li>
                      </ol>
                    </div>
                    <div>
                    </div>
                    <a href="#" class="ml-3" @click="toggleShowMore" style="font-size: 13px; width:100px">See
                      Less<feather-icon icon="ChevronsUpIcon" /></a>
                  </div>
                </div>
                <div v-else>
                  <div style="display: flex; justify-content:center">
                    <div>
                      <ol>
                        <li class="account-name text-style" v-for="(item, index) in truncatedFacebookData" :key="index">
                          {{ item }}
                        </li>
                      </ol>
                    </div>
                    <div>
                      <a href="#" class="ml-3" @click="toggleShowMore" style="font-size: 13px; width:100px">See more
                        <feather-icon icon="ChevronsDownIcon" /></a>
                    </div>
                  </div>


                </div>
                <div class="mt-2">
                  <b-button block v-if="integrations.facebook" variant="primary"
                    :to="`/client/${slug}/dashboard/meta-ads`" v-b-tooltip.hover.top="'Meta Ads'">See Meta
                    Ads
                    Data</b-button>
                  <b-button :disabled="role === 'VIEWER'" @click="selectFacebookAdsAccount" variant="flat-dark-outline">
                    <b-spinner v-if="isFetchingFacebookAccounts" small />
                    <span class="customize-text"> <span v-if="role === 'VIEWER'"><b-icon class="upDownIcon"
                          icon="dash-circle" /></span>Reselect Ads Account(s)</span></b-button>
                </div>
              </div>
            </b-card>
          </div>
          <div v-else>
            <b-card variant="primary" class="text-center py-2 px-2">
              <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/meta.png">
              </b-avatar>
              <div class="truncate">
                <h3 class="mb-25 font-weight-bolder">Meta Ads</h3>
                <div style="font-size: 10px;" class=" text-style" v-for="(item, index) in facebookAccountName"
                  :key="index">
                  <p style="font-size: 12px; text-align:center">{{ item }}</p>
                </div>
                <div class="mt-2">
                  <b-button block @click="facebookConnect" v-if="!integrations.facebook"
                    variant="outline-primary">Connect</b-button>
                  <b-button v-if="integrations.facebook" :disabled="role === 'VIEWER'" @click="selectFacebookAdsAccount"
                    variant="primary" :class="next === 'google-analytics-account-selection' ||
                      !googleViewID
                      ? 'pulse-button'
                      : ''
                      ">
                    <b-spinner v-if="isFetchingFacebookAccounts" small />
                    <span v-if="integrations.facebook">Select Ads Account(s)</span></b-button>
                </div>
              </div>
            </b-card>
          </div>
        </b-col> -->
              <!-- LeadSquare Ads -->
      <b-col>
        <b-card variant="primary" class="text-center py-2 px-2">
          <b-avatar square style="margin-top:-15px"class="mb-1" variant="white" size="60" src="@/assets/images/logo/leadSquared.png">
          </b-avatar>
          <div class="truncate">
            <h3 class="mb-25 font-weight-bolder">LeadSquared</h3>
            <div v-if="integrations.leadSquared" >
              <div v-if="this.leadSquaredDetails && Object.keys(this.leadSquaredDetails).length > 0" style="text-align: left; margin-top:10px;"> 
                <p><b>API Key :</b> {{ this.leadSquaredDetails  &&  this.leadSquaredDetails.accessKey  && this.leadSquaredDetails.accessKey.slice(0, 19).replaceAll("*","X") }}</p>
                <p style="margin-top: -12px;"><b>Secret Key :</b> {{ this.leadSquaredDetails  && this.leadSquaredDetails.secretKey  &&  this.leadSquaredDetails.secretKey.slice(0, 16).replaceAll("*","X") }}</p>
              </div>
            </div>
            <div class="mt-2">
              <div  v-if="integrations.leadSquared">
                <b-button block v-if="integrations.leadSquared" variant="primary"
                :to="`/client/${slug}/dashboard/leadsquared`" v-b-tooltip.hover.top="'View Meta Ads Data'">
                Continue</b-button>
              <b-button :disabled="role === 'VIEWER'" @click="openLeadSquareModal"
                variant="flat-dark-outline">
                <!-- <b-spinner v-if="isFetchingFacebookAccounts" small /> -->
                <span class="customize-text"> <span v-if="role === 'VIEWER'"><b-icon class="upDownIcon"
                      icon="dash-circle" /></span>Reselect Ads Account(s)</span></b-button>
              </div>
              <div v-else>
                <b-button block @click="openLeadSquareModal" v-if="!integrations.amazon"
                variant="outline-primary">Connect</b-button>
              </div>
              <!-- <b-button block v-if="!integrations.leadSquared" @click="openLeadSquareModal" variant="outline-primary">Connect</b-button>
              <b-button :class="next === 'facebook-ads-customer-selection'
                ? 'pulse-button'
                : ''
                "  v-else variant="primary" @click="openLeadSquareModal">Reselect Account</b-button> -->
            </div>
          </div>
        </b-card>
      </b-col>
      </b-row>
      <p class="mb-1 mt-1 section-title text-capitalize text-white text-with-shadow">coming soon</p>
      <b-row>
  <!-- Shopify Ads -->
  <b-col>
    <b-card variant="primary" class="text-center py-1 px-1">
      <b-avatar  style="width: 85px; height:85px; height:auto;" square class="mb-1" variant="white" size="45" src="@/assets/images/logo/salesforce.svg">
      </b-avatar>
      <div class="truncate">
        <h3 class="mb-25 font-weight-bolder">SalesForce</h3>
        <div class="mt-2">
          <b-button block  v-if="!integrations.shopify"
            variant="outline-primary">Connect</b-button>
          <b-button block v-else variant="outline-primary">Connected</b-button>
          <!-- <b-button :class="next === 'facebook-ads-customer-selection'
            ? 'pulse-button'
            : ''
          " disabled v-else variant="primary">Reselect Account</b-button>-->
        </div>
      </div>
    </b-card>
  </b-col>
        <!-- Instagram Ads -->
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-avatar style="width: 200px;" square class="mb-1" variant="white" size="45" src="@/assets/images/logo/hubspot.svg">
            </b-avatar>
            <div class="truncate">
              <h3 class="mb-25 font-weight-bolder">Hubspot</h3>
              <div class="mt-2">
                <b-button block disabled v-if="true" variant="outline-primary">Connect</b-button>
                <b-button :class="next === 'facebook-ads-customer-selection'
                  ? 'pulse-button'
                  : ''
                  " disabled v-else variant="primary">Reselect Account</b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <!-- Flipkart Ads -->
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/Flipkart-Emblem.png">
            </b-avatar>
            <div class="truncate">
              <h3 class="mb-25 font-weight-bolder">Flipkart</h3>
              <div class="mt-2">
                <b-button block disabled v-if="true" variant="outline-primary">Connect</b-button>
                <b-button :class="next === 'facebook-ads-customer-selection'
                  ? 'pulse-button'
                  : ''
                  " disabled v-else variant="primary">Reselect Account</b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>

        <!-- Myntra Ads -->
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/download.png">
            </b-avatar>
            <div class="truncate">
              <h3 class="mb-25 font-weight-bolder">Myntra</h3>
              <div class="mt-2">
                <b-button block disabled v-if="true" variant="outline-primary">Connect</b-button>
                <b-button :class="next === 'facebook-ads-customer-selection'
                  ? 'pulse-button'
                  : ''
                  " disabled v-else variant="primary">Reselect Account</b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-avatar square class="mb-1" variant="white" size="45" style="width: 120px;"
              src="@/assets/images/logo/branch-logo_dark.svg">
            </b-avatar>
            <div class="truncate">
              <h3 class="mb-25 font-weight-bolder">Branch</h3>
              <div class="mt-2">
                <b-button block disabled v-if="true" variant="outline-primary">Connect</b-button>
                <b-button :class="next === 'facebook-ads-customer-selection'
                  ? 'pulse-button'
                  : ''
                  " disabled v-else variant="primary">Reselect Account</b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-avatar square class="mb-1" variant="white" style="width: 100px;" size="45"
              src="@/assets/images/logo/appsFlyer.png">
            </b-avatar>
            <div class="truncate">
              <h3 class="mb-25 font-weight-bolder">AppsFlyer</h3>
              <div class="mt-2">
                <b-button block disabled v-if="true" variant="outline-primary">Connect</b-button>
                <b-button :class="next === 'facebook-ads-customer-selection'
                  ? 'pulse-button'
                  : ''
                  " disabled v-else variant="primary">Reselect Account</b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
          <!-- Linkedin Ads -->
          <b-col>
            <b-card variant="primary" class="text-center py-2 px-2">
              <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/linkedin.png">
              </b-avatar>
              <div class="truncate">
                <h3 class="mb-25 font-weight-bolder">Linkedin</h3>
                <div class="mt-2">
                  <b-button block disabled v-if="true" variant="outline-primary">Connect</b-button>
                  <b-button :class="next === 'facebook-ads-customer-selection'
                    ? 'pulse-button'
                    : ''
                    " disabled v-else variant="primary">Reselect Account</b-button>
                </div>
              </div>
            </b-card>
          </b-col>
        <!-- Shopify Ads -->
  <b-col>
    <b-card variant="primary" class="text-center py-2 px-2">
      <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/Shopify.png">
      </b-avatar>
      <div class="truncate">
        <h3 class="mb-25 font-weight-bolder">Shopify</h3>
        <div class="mt-2">
          <b-button block @click="showShopNameModal" v-if="!integrations.shopify"
            variant="outline-primary">Connect</b-button>
          <b-button block v-else variant="outline-primary">Connected</b-button>
          <!-- <b-button :class="next === 'facebook-ads-customer-selection'
            ? 'pulse-button'
            : ''
          " disabled v-else variant="primary">Reselect Account</b-button>-->
        </div>
      </div>
    </b-card>
  </b-col>
        <!-- Instagram Ads -->
        <b-col>
          <b-card variant="primary" class="text-center py-2 px-2">
            <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/2227.jpg">
            </b-avatar>
            <div class="truncate">
              <h3 class="mb-25 font-weight-bolder">Instagram</h3>
              <div class="mt-2">
                <b-button block disabled v-if="true" variant="outline-primary">Connect</b-button>
                <b-button :class="next === 'facebook-ads-customer-selection'
                  ? 'pulse-button'
                  : ''
                  " disabled v-else variant="primary">Reselect Account</b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div class="modal-content modal">
        <b-modal v-model="shopNameModal" id="showShopNameModal" hide-footer hide-header centered size="small"
          no-close-on-backdrop>
          <b-card-text>
            <div class="header text-right">
              <feather-icon style="cursor: pointer" @click="closeShopifyNameModal" class="Xicon"
                icon="XIcon"></feather-icon>
            </div>
            <div class="modal-item">
              <div class="pr-1 edit-form">
                <h6 class="app-title lg">
                  Shop Name
                </h6>
              </div>
            </div>
          </b-card-text>
          <b-row>
            <b-col cols="8"> <b-form-input v-model="shopName"></b-form-input></b-col>
            <b-col cols="4">
              <b-button variant="primary" block id="show-btn" @click="shopifyConnect">
                Connect</b-button>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </div>
    <b-modal v-model="showConnectLeadSquareModal" id="showShopNameModal" hide-footer hide-header centered size="md">
      <b-card-text>
        <div class="header text-right">
          <feather-icon style="cursor: pointer" @click="closeLeadSquareModal" class="Xicon" icon="XIcon"></feather-icon>
        </div>
        <div class="modal-item">
          <div class="pr-1 edit-form">
            <h3 class="app-title sm mb-2" style="margin-top: -15px;">
             Provide the following details
            </h3>
          </div>
          <div>
            <label>Please enter a valid details to proceed.</label>
            <b-input-group>
              <template #prepend>
                <b-input-group-text ><span style="font-weight:bold" >API KEY</span></b-input-group-text>
              </template>
              <b-form-input v-model="apiKey" ></b-form-input>
              <template #append>
              </template>
            </b-input-group>
            <b-input-group class="mt-1" >
              <template #prepend>
                <b-input-group-text ><span style="font-weight:bold" >API SECRET</span></b-input-group-text>
              </template>
              <b-form-input v-model="apiSecret" ></b-form-input>
              <template #append>
              </template>
            </b-input-group>
          </div>
          <b-row class="mt-1">
            <b-col cols="9"></b-col>
            <b-col cols="3">  <b-button :disabled="isSaveLeadSquareDisabled" @click="saveLeadSquareIDs" style="margin-left: 27px;" variant="primary">
              <div class="d-flex justify-content-center align-items-center">
                <!-- <feather-icon icon="PlusIcon"></feather-icon> -->
                <span>Save <b-spinner v-if="isLeadSquaredSave" small /></span>
              </div>
            </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal v-model="shopAddProjectModal" id="showShopNameModal" hide-footer hide-header centered size="lg">
      <b-card-text>
        <div class="header text-right">
          <feather-icon style="cursor: pointer" @click="closeAddModalProject" class="Xicon" icon="XIcon"></feather-icon>
        </div>
        <div class="modal-item">
          <div class="pr-1 edit-form">
            <h6 class="app-title lg">
              Add Project 
            </h6>
          </div>
        </div>
      </b-card-text>
      <b-row style="padding:6px;">
        <div class="full-width-div">
  <div class="container-fluid"
  v-if="this.allPermission.create == 'true'"
   >
    <div class="row">
      <div class="col-12 col-md-4 mb-1">
        <b-form-group label="*Project Name" label-for="project-name">
          <b-form-input id="project-name" v-model="projectName" placeholder="Enter project name" required></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 col-md-3 mb-1">
        <b-form-group label="Rules" label-for="rules-select">
          <b-form-select id="rules-select" v-model="selectedCampaigns" :options="campaignstype" disabled required></b-form-select>
        </b-form-group>
      </div>
      <div class="col-12 col-md-3 mb-1">
        <b-form-group label="*Tag" label-for="tag-input">
          <b-form-input id="tag-input" v-model="projectDescription" placeholder="Enter filter tag" required></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12 col-md-2 mt-2">
        <b-button variant="primary" @click="addAProject" class="w-100" :disabled="isCreateButtonDisabled">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon icon="PlusIcon"></feather-icon>
            <span>Add</span>
            <b-spinner v-if="isCreatedProject" small></b-spinner>
          </div>
        </b-button>
      </div>
    </div>
  </div>
</div>
      </b-row>
      <!-- <div style="display: flex; justify-content: space-between"> -->
      <!-- </div> -->
      <div style="display: flex; justify-content: center;padding: 10px;">
    <b-spinner v-if="isFetchComplete" large ></b-spinner>
</div>
      <div v-if="projectList.length > 0 && this.allPermission.read == 'true'">
        <p>Project list:</p>
        <div>
          <b-list-group v-for="(i, index) in projectList" :key="i.projectID">
            <!-- Edit -->
            <!-- <div v-if="editingProjectId === i.projectID" style="display: flex; justify-content: space-between">
              <b-row style="padding:6px">
                  <b-col cols="4"> <b-form-group>
                  <label for="client-name">Project Name <span class="validation">*</span></label>
                  <b-form-input style="height: 56px; min-width: 250px; margin-right: 140px" v-model="editProjectName" id="client-name"
                      placeholder="Enter project name" />
                </b-form-group></b-col>
              <b-col cols="3"> <b-form-group>
                  <label for="client-name">Rules<span class="validation">*</span></label>
                  <v-select :options="campaignstype" v-model="selectedCampaigns" :clearable="false" :disabled="true"></v-select>
                </b-form-group></b-col>
                <b-col cols="3"> <b-form-group>
                  <label for="client-name">Tag <span class="validation">*</span></label>
                  <b-form-input style="height: 56px; min-width: 200px;" v-model="projectDescription" id="client-name"
                    placeholder="Enter Description" />
                </b-form-group></b-col> -->
            <!-- <b-col cols="4">
                  <b-form-group>
                    <label for="client-name">Project Name <span class="validation">*</span></label>
                    <b-form-input style="height: 56px; min-width: 250px; margin-right: 140px" v-model="editProjectName" id="client-name"
                      placeholder="Enter client name" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <label for="client-name">Select Campaigns<span class="validation">*</span></label>
                    <v-select style="height: 35px !important; min-width: 250px;" 
                    :style="{ height: vSelectHeight }" 
                    label="name" 
                    :loading="isCampaignsLoading"
                    placeholder="Select Campaign(s)" 
                    v-model="editSelectedCampaigns" 
                    multiple 
                    :options="campaigns" 
                    :value="editSelectedCampaigns" />
                  </b-form-group>
                </b-col> -->

            <!-- <b-col cols="2">
                  <b-button variant="primary" style="float: right; margin-top: 28px;height: 40px; min-width: 100px;"
                    @click="updateAProject(true)">
                    <feather-icon icon="EditIcon"></feather-icon> Edit<b-spinner v-if="isUpdatedProject" small />
                  </b-button>
                </b-col>
              </b-row>
            </div> -->
            <b-row>
              <b-col cols="12">
                <b-list-group-item>
                  <b-row>
                    <b-col cols="4" @click="toggleProjectVisibility(index)" style="cursor:pointer">
                      <div>
                        <feather-icon icon="CheckIcon" color="green" /><strong> {{ i.projectName }}</strong>
                      </div>
                    </b-col>
                    <b-col cols="6" @click="toggleProjectVisibility(index)" style="cursor:pointer">
                     <span style="font-size: 12px;"> Campaign(s) Contain </span><span  style="font-size: 12px;" v-for="(text, it) in i.rules">
                       <strong>{{ text.value }}</strong><span v-if="it !== i.rules.length - 1">, </span>
                      </span>
                    </b-col>
                    <b-col cols="2">
                      <div style="float: right;">
                        <div style="display: flex; justify-content: space-between; gap:5px">
                          <span variant="primary" pill style="cursor:pointer" @click="toggleProjectVisibility(index)"
                            v-b-tooltip.hover.top="viewProjectId !== '' ? 'Hide Selected Campaigns' : 'View Selected Campaigns'">
                            <feather-icon :icon="viewProjectId === index ? 'XIcon' : 'EyeIcon'" color="#200E6B" />
                          </span>
                          <!-- <span variant="primary" pill style="cursor:pointer"
                @click="editProject(i.projectID, i.campaigns, i.projectName , i)"><feather-icon icon="EditIcon" /></span> -->
                          <span variant="primary" pill style="cursor:pointer"  v-if="checkDelete == 'true'"
                            @click="deleteModalOpen(i.projectID, i.campaigns, i.projectName, false)"><feather-icon
                              color="#200E6B" icon="TrashIcon" /></span>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12">
                    <div v-if="viewProjectId === index" style="padding: 4px;">
                      <b-row>
                        <b-col cols=12>
                          <div v-if="i.campaigns.length === 0" style="padding:10px; width:100%; align-items:center ; text-align: center; font-size: 11px">
                        <span>No campaign containing {{ i.tagName }} is available.</span>
                        </div>
                          </b-col>
                        </b-row>
                      <!-- Grid container with two columns -->
                      <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px; font-size: 12px;">
                 
                        <!-- Iterate through items and place each in a cell -->
                        <div v-for="(item, l) in i.campaigns" :key="item.id">
                          {{ l + 1 }}: {{ item }} <!-- Displaying the name property, change as needed -->
                        </div>
                      </div>
                    </div>

                    </b-col>       
                  </b-row>
                </b-list-group-item>
              </b-col>
            </b-row>
          </b-list-group>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="showDeleteModal" id="showShopNameModal" hide-footer hide-header centered size="sm">
      <div class="header text-right">
        <feather-icon style="cursor: pointer" @click="closeDeleteModal" class="Xicon" icon="XIcon"></feather-icon>
      </div>
      <div class="d-block text-center">
        <h6>Are you sure you want to delete this <strong>{{ deleteProjectName }}</strong> project?</h6>
      </div>
      <div class="popup-modal">
        <b-button class="mt-2" @click="closeDeleteModal" style="min-width: 150px">Cancel</b-button>
        <b-button style="min-width: 150px" class="ml-1 mt-2" variant="primary" @click="
          deleteAProject()">
          Yes
        </b-button>
      </div>
      <div>
      </div>
    </b-modal>
  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BButton,
  BAvatar,
  BSpinner,
  VBPopover,
  VBTooltip,
  BLink,
  BModal,
  BCardText,
  BFormInput,
  BIcon,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  BInputGroupText,
  BInputGroup
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BSpinner,
    VBPopover,
    BLink,
    BModal,
    BCardText,
    BFormInput,
    BIcon,
    BFormGroup,
    vSelect,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BInputGroupText,
    BInputGroup
  },
  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    integrations: {
      type: Object,
      default: () => {
        return {
          google: null,
          facebook: null,
          googleAds: null,
        };
      },
    },
    allPermission:{
      type:Object,
      default: () =>{
        return{
        create: null,
        delete: null,
        read: null,
        }
      }
    },
    isProjectEnabled:{
      type:Boolean
    },
    isFetchingAccounts: {
      type: Boolean,
    },
    accountID: {
      type: Array,
    },
    isFetchingAdsCustomers: {
      type: Boolean,
    },
    isFetchingGMBAccounts: {
      type: Boolean,
    },
    isAmazonProfileLoading: {
      type: Boolean,
    },
    isFetchingFacebookAccounts: {
      type: Boolean,
    },
    googleViewID: {
      type: String,
    },
    googleAdError: {
      type: Boolean,
    },
    googleViewProfile: {
      type: String,
    },
    googleAdsCustomerID: {
      type: String,
    },
    gmbName: {
      type: Array,
    },
    leadSquaredDetails: {
      type: Object,
    },
    facebookAccountName: {
      type: Array,
    },
    amazonProfileName: {
      type: Array,
    },
    next: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    shopName: {
      type: String
    },
    mainId: {
      type: String
    },
    // shopNameModal:{
    //   type:String
    // },
    googleAds: {
      type: Object,
      default: () => {
        return {
          googleAdsCustomerID: null,
          manager: false,
          customerClientID: null,
          managerName: null,
          customerName: null,
          customerNames: null,
        };
      },
    },
    role: {
      type: String,
      required: true,
      default: "VIEWER",
    },
  },
  data() {
    return {
      apiSecret:"",
      apiKey:"",
      isLeadSquaredSave:false,
      showConnectLeadSquareModal:false,
      googleCustomerNames:[],
      checkDelete:"",
      warningHandle:false,
      campaignstype: [
        {
          text: "Contains",
          value: "contains"
        }
      ],
      deleteProjectName: "",
      deleteProjectID: "",
      deleteCampaigns: [],
      deleteflag: false,
      editingProjectId: "",
      viewProjectId: "",
      isEditProjectCliecked: false,
      projectName: "",
      projectDescription: "",
      shopAddProjectModal: false,
      channel: "",
      showMore: false,
      showGoogleMore: false,
      showMetaMore: false,
      maxLength: 2,
      shopNameModal: false,
      isCampaignsLoading: true,
      campaigns: [],
      selectedCampaigns: "contains",
      isCreatedProject: false,
      isUpdatedProject: false,
      showListModal: false,
      showDeleteModal: false,
      projectList: [],
      editSelectedCampaigns: [],
      editProjectName: "",
      editProjectDescription: "",
      isFetchComplete: false,
      viewProjectDesc: false
    }
  },
  created() {
    // Assuming allPermission is fetched asynchronously or updated later
    // Simulating asynchronous data fetching
    setTimeout(() => {
      // Updating checkDelete based on allPermission.delete
      this.checkDelete = this.allPermission.delete;
    }, 2000); // Simulated delay of 2 seconds
  },
  computed: {
    isSaveLeadSquareDisabled(){
      return this.apiSecret.length < 40 || this.apiKey.length < 35
    },
    isCreateButtonDisabled(){
      return this.projectName.length === 0 || this.projectDescription.length === 0
    },
    vSelectHeight() {
      const baseHeight = 35; // Minimum height in pixels
      const itemHeight = 28; // Additional height per item
      const selectedCount = this.editSelectedCampaigns.length;
      return `${baseHeight + itemHeight * selectedCount}px !important`;
    },
    truncatedData() {
      if (this.gmbName.length > this.maxLength) {
        return this.gmbName.slice(0, this.maxLength);
      }
      return this.gmbName;
    },
    truncatedGoogleData() {
      if (this.googleAds.customerNames.length > this.maxLength) {
        return this.googleAds.customerNames.slice(0, this.maxLength);
      }
      return this.googleAds.customerNames;
    },
    truncatedFacebookData() {
      if (this.facebookAccountName.length > this.maxLength) {
        return this.facebookAccountName.slice(0, this.maxLength);
      }
      return this.facebookAccountName;
    },
    truncatedGoogleAdsData() {
      if (this.googleAds.customerName.length > this.maxLength) {
        return this.googleAds.customerName.slice(0, this.maxLength);
      }
      return this.googleAds.customerName;
    },
  },
  methods: {
   async saveLeadSquareIDs(){
    this.isLeadSquaredSave = true
      try {
          const response = await axios.post(
              `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/leadsquared/keys`, {
                clientID:this.mainId,
                accessKey:this.apiKey,
                secretKey:this.apiSecret
              },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            if (response.status === 200) {
              this.isLeadSquaredSave = false
              this.showToast("Logged In", "Logged in successfully!", "success");
            }else{
              console.log("something went wrong.");
            }
          } catch (error) {
            this.isLeadSquaredSave = false
            this.showToast("Error", "Unknown error occurred.", "danger");
          }
    },
    openLeadSquareModal(){
      this.showConnectLeadSquareModal = true
    },
    closeLeadSquareModal(){
      this.showConnectLeadSquareModal = false
    },
    toggleProjectVisibility(index) {
      if (this.viewProjectId === index) {
        // If the clicked project is already being viewed, hide it
        this.viewProjectId = '';
      } else {
        // Show the clicked project and hide others
        this.viewProjectId = index;
      }
    },
    // editProject(id, campaigns, names , des) {
    //   this.editingProjectId = id
    //   this.editSelectedCampaigns = campaigns
    //   this.editProjectName = names
    //   this.editProjectDescription = des
    // },
    viewProject(id) {
      this.viewProjectId = id
      this.viewProjectDesc != this.viewProjectDesc
    },
    viewCancelProject() {
      this.viewProjectId = null
      // this.viewProjectDesc !=  this.viewProjectDesc
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    openListModal() {
      this.fetchAllProjects();
      this.showListModal = true
    },
    closeListModal() {
      this.showListModal = false
    },
    closeDeleteModal() {
      this.showDeleteModal = false
    },
    fetchAllProjects() {
      this.isFetchComplete = true
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/google-ads/projects/?clientID=${this.mainId}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.projectList = response.data.projects
          this.isFetchComplete = false
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isFetchComplete = false
        });
    },
    addAProject() {
      this.isCreatedProject = true
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/projects/`,
          {
            clientID: this.mainId,
            projectName: this.projectName,
            tagName:this.projectDescription,
            rules: [
              {
                field: "campaignName",
                value: this.projectDescription,
                operator: this.selectedCampaigns
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchAllProjects();
          this.projectName = ""
          this.projectDescription = ""
          // this.selectedCampaigns = []
          this.showToast("Added", "Project Added successfully!", "success");
          this.isCreatedProject = false
          // this.shopAddProjectModal = false
        }).catch((error) => {
          // this.shopAddProjectModal = false
          this.isCreatedProject = false
          this.showToast("Error", error.response.data.error, "danger");
        })
    },
    // updateAProject(activeState) {
    //   this.isUpdatedProject = true
    //   axios
    //     .post(
    //       `${process.env.VUE_APP_SERVER_URL}/api/google-ads/projects/`,
    //       {
    //         clientID: this.mainId,
    //         projectID: this.editingProjectId,
    //         projectName: this.editProjectName,
    //         campaigns: this.editSelectedCampaigns,
    //         isActive:activeState
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${getUserToken()}`,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.fetchAllProjects();
    //       this.showToast("Updated", "Project Updated successfully!", "success");
    //       this.isUpdatedProject = false
    //       this.editingProjectId = ""
    //       this.editSelectedCampaigns = []
    //       this.editProjectName = "names"
    //       // this.shopAddProjectModal = false
    //     }).catch((error) => {
    //       // this.shopAddProjectModal = false
    //       this.isUpdatedProject = false
    //       this.editingProjectId = ""
    //       this.editSelectedCampaigns = []
    //       this.editProjectName = "names"
    //       this.showToast("Error", "Error updating the graph", "danger");
    //     })
    // },
    deleteModalOpen(projectId, campaigns, name, activeState) {
      this.deleteProjectName = name
      this.deleteProjectID = projectId
      this.deleteCampaigns = campaigns
      this.deleteflag = activeState
      this.showDeleteModal = true
    },
    deleteAProject() {
      this.isUpdatedProject = true
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/projects/?projectID=${this.deleteProjectID}&clientID=${this.mainId}`,
          // {
          //   projectID: this.deleteProjectID,
          //   clientID: this.mainId,
          // },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchAllProjects();
          this.showToast("Deleted", "Project Deleted successfully!", "success");
          this.isUpdatedProject = false
          this.editingProjectId = ""
          this.editSelectedCampaigns = []
          this.editProjectName = "names"
          // this.shopAddProjectModal = false
          this.showDeleteModal = false
        }).catch((error) => {
          // this.shopAddProjectModal = false
          this.isUpdatedProject = false
          this.editingProjectId = ""
          this.editSelectedCampaigns = []
          this.editProjectName = "names"
          this.showToast("Error", error.response.data.error, "danger");
          this.showDeleteModal = false
        })
    },
    getCampaigns() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-campaigns`,
          {
            clientID: this.mainId,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isCampaignsLoading = false;
          this.campaigns = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isCampaignsLoading = false;
        });
    },
    closeAddModalProject() {
      this.projectList = []
      this.shopAddProjectModal = false
      this.editingProjectId = ""
    },
    showAddModalProject() {
      this.fetchAllProjects();
      this.getCampaigns();
      this.editingProjectId = null
      this.shopAddProjectModal = true
    },
    showShopNameModal() {
      this.shopNameModal = true;
    },
    closeShopifyNameModal() {
      this.shopNameModal = false;
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    toggleMetaShowMore() {
      this.showMetaMore = !this.showMetaMore;
    },
    toggleGoogleShowMore() {
      this.showGoogleMore = !this.showGoogleMore;
    },
    fetchAnalyticsAccounts() {
      this.$emit("fetchAnalyticsAccounts", true);
    },
    googleAnalyticsConnect() {
      this.$emit("googleAnalyticsConnect", true);
    },
    googleAdsConnect() {
      this.$emit("googleAdsConnect", true);
    },
    facebookConnect() {
      this.$emit("facebookConnect", true);
    },
    amazonConnect() {
      this.$emit("amazonConnect", true);
    },
    shopifyConnect() {
      this.closeShopifyNameModal();
      this.$emit("shopifyConnect", true);
    },
    gmbConnect() {
      this.$emit("gmbConnect", true);
    },
    selectGoogleAdsCustomers() {
      this.$emit("selectGoogleAdsCustomers", true);
    },
    selectAmazonProfile() {
      this.$emit("selectAmazonProfile", true);
    },
    fetchFacebookAdAccounts() {
      this.$emit("fetchFacebookAdAccounts", true);
    },
    selectGMBCustomers() {
      this.$emit("selectGMBCustomers", true);
    },
    selectFacebookAdsAccount() {
      this.$emit("selectFacebookAdsAccount", true);
    },
  },
};
</script>

<style>
.upDownIcon {
  margin-right: 4px;
}

.text-with-shadow {
  text-shadow: 2px 2px 4px rgba(170, 170, 170, 0.8);
}

.customize-text {
  text-decoration: underline;
  color: #6b6b6b !important;
}

p.section-title {
  font-size: 16px;
  font-weight: 600;
}

p.account-name {
  font-size: 14px;
  font-weight: 600;
  color: #6b6b6b;
  margin-bottom: 0;
}

p.manager-account {
  font-size: 12px;
  font-weight: 500;
  color: #999999;
}

.google-pulse-button,
.gmb-pulse-button,
.facebook-pulse-button,
.amazon-pulse-button,
.pulse-button {
  box-shadow: 0 0 0 0 #7367f0;
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 1.5s infinite;
}

.google-pulse-button:hover,
.google-pulse-button:hover,
.pulse-button.hover {
  -webkit-animation: none;
  animation: none;
}

.text-style {
  font-size: 14px !important;
  font-weight: bold;
  text-align: left;
  justify-content: left;
}

@keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(90, 153, 212, 0);
  }

  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

@media (min-width: 1024px) and (max-width: 1500px) {
  .text-style-facebook {
    text-align: left;
    font-size: 11px;
  }
}

.full-width-div {
  width: 100% !important;
}

</style>