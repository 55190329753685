<template>
  <div>
    <b-modal
      @hidden="toggleCustomerSelectorModal"
      @ok="toggleCustomerSelectorModal"
      v-model="internalModalVisible"
      hide-footer
      no-close-on-backdrop
      centered
      size="lg"
      title="Select Customer"
    > 
      <b-row class="center-text-no-found" v-if="this.adsCustomers.length === 0">
            <div >
              <p>No data found!</p>
            </div>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <b-card-text>
            <app-collapse type="border" class="mainContainer">
              <app-collapse-item
                :class="
                  !customer.descriptiveName
                    ? 'strike-through'
                    : customer.manager && !customer.childAccounts
                    ? 'strike-through'
                    : ''
                "
                v-for="(customer, customerIndex) in adsCustomers"
                :key="customer.id"
                v-if="customer.manager"
                :title="
                  `${customer.descriptiveName}${
                    customer.manager ? ' (MCC)' : ''
                  }`
                "
              >
                <input
                  placeholder="search customer.."
                  class="search-option"
                  v-model="searchCustomer"
                />
                <div v-if="customer.manager">
                  <b-list-group>
                    <b-list-group-item
                      v-for="(child, childIndex) in filterOverAllAccount"
                      :key="child.customerClient.id"
                      class="pt-1 pb-1"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between ml-1 d-inline"
                        style="margin-top: 8px;"
                      >
                        <div>
                          <div>
                            <h5>{{ child.customerClient.descriptiveName }}</h5>
                            <h6>{{ child.customerClient.id }}</h6>
                          </div>
                        </div>
                        <div class="radio-container">
                          <b-form-checkbox
                            :checked="isSelected(child.customerClient)"
                            @change="
                              toggleSelection(
                                child.customerClient,
                                customerIndex,
                                childIndex,
                                customer.id,
                                customer.manager,
                                customer.descriptiveName
                              )
                            "
                            :id="String(childIndex)"
                          ></b-form-checkbox>
                        </div>
                      </div>
                    </b-list-group-item>
                    <div
                      style="justify-content: center; margin-top: 10px; height: 1;"
                    >
                      <b-pagination
                        v-model="currentPage"
                        v-if="filterOverAllAccount.length > 0"
                        :total-rows="totalGoogleRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                      ></b-pagination>
                    </div>
                    <!-- <b-row style="margin-top: 5px; margin-left: 15px;">
                    <b-col cols="12">
                      <div v-if="isFacebookCustomersEmpty"></div>
                    </b-col>
                  </b-row> -->
                  </b-list-group>
                </div>

                <b-card v-else>
                  <b-list-group>
                    <b-list-group-item>
                      <div
                        class="d-flex align-items-center justify-content-between ml-1 d-inline"
                        style="margin-top: 8px;"
                      >
                        <div>
                          <div>
                            <h5>{{ customerClient.descriptiveName }}</h5>
                            <h6>{{ customerClient.id }}</h6>
                          </div>
                        </div>
                        <div class="radio-container">
                          <b-form-checkbox
                            :checked="isSelected(customerClient)"
                            @change="
                              toggleSelection(
                                customerIndex,
                                childIndex,
                                customer.id,
                                customer.manager,
                                null,
                                customer.descriptiveName,
                                null
                              )
                            "
                            :id="String(customerIndex)"
                          ></b-form-checkbox>
                        </div>
                      </div>
                    </b-list-group-item>
                    <div
                      class="pagination"
                      style="display: flex; justify-content: center !important; align-items: center; margin-top: 5px; height: 1;"
                    >
                      <b-pagination
                        v-model="currentPage"
                        v-if="filterOverAllAccount.length > 0"
                        :total-rows="totalGoogleRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        align="center"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                      ></b-pagination>
                    </div>
                    <!-- <b-row style="margin-top: 5px; margin-left: 15px;">
                    <b-col cols="12">
                      <div v-if="isFacebookCustomersEmpty"></div>
                    </b-col>
                  </b-row> -->
                  </b-list-group>

                  <!-- <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h5>{{ customer.descriptiveName }}</h5>
                      <h6>{{ customer.id }}</h6>
                    </div>
                    <div>
                      <b-button
                        @click="
                          selectGoogleCustomerAccount({
                            customerIndex,
                            childIndex: null,
                            customerID: customer.id,
                            manager: customer.manager,
                            customerClientID: null,
                            managerName: customer.descriptiveName,
                            customerName: null,
                          })
                        "
                        variant="primary"
                        ><b-spinner v-if="customer.loading" small /> Select
                        Customer</b-button
                      >
                    </div>
                  </div> -->
                </b-card>
                <b-row >
                  <b-col cols="12">
                    <div
                      style="display:flex; justify-content:space-between; gap:10px;margin-top:5px;padding: 0px 8px !important;"
                    >
                      <b-button
                        variant="outline-primary"
                        class="mr-1"
                        @click="closeModal"
                        >Cancel</b-button
                      >
                      <b-button variant="primary" @click="submitData"
                        >Submit</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-card-text>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BModal,
  BButton,
  BBadge,
  BCardText,
  BSpinner,
  BFormCheckbox,
  BListGroupItem,
  BListGroup,
  BPagination,
  BCol,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  props: {
    adsCustomerSelectorModal: {
      type: Boolean,
      default: true,
    },
    adsCustomers: {
      type: Array,
      default: [],
    },
    googleSelected: {
      type: Array,
    },
    googleSelectedIds: {
      type: Array,
    },
    googleSelecteds: {
      type: Array,
    },
  },
  data() {
    return {
      dataform: {},
      // googleSelected: [],
      localGoogleSelected: this.googleSelected,
      perPage: 6,
      userData: [],
      currentPage: 1,
      searchCustomer: "",
      internalModalVisible: this.adsCustomerSelectorModal,
    };
  },
  components: {
    BCard,
    BRow,
    BModal,
    BButton,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BCardText,
    BSpinner,
    BFormCheckbox,
    BListGroupItem,
    BListGroup,
    BPagination,
    BCol,
  },
  watch: {
    googleSelected(newVal) {
      this.localGoogleSelected = newVal; // Update the local property when the prop changes
    },
    adsCustomerSelectorModal(value) {
      this.internalModalVisible = value;
    },
  },
  computed: {
    totalGoogleRows() {
      return this.filterClientData.length;
    },
    filterClientData() {
      this.currentPage = 1;
      var clientDataForSearch = this.adsCustomers[0].childAccounts;
      var authorNameSearchString = this.searchCustomer;

      if (!authorNameSearchString) {
        return clientDataForSearch;
      }

      var searchString = authorNameSearchString.trim().toLowerCase();
      clientDataForSearch = clientDataForSearch.filter(function(item) {
        if (
          item.customerClient.descriptiveName
            .toLowerCase()
            .indexOf(searchString) !== -1
        ) {
          return item;
        }
      });

      return clientDataForSearch;
    },
    filterOverAllAccount() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filterClientData.slice(start, end);
    },
  },
  methods: {
    closeModal() {
      this.internalModalVisible = false;
    },
    isSelected(client) {
      return (
        this.googleSelectedIds &&
        this.googleSelectedIds.some(
          (selectedName) => selectedName === client.id
        )
      );
    },
    toggleSelection(
  client,
  customerIndex,
  childIndex,
  customerid,
  manager,
  descriptiveName
) {
  // Initialize dataform with current values
  this.dataform = {
    customerIndex,
    childIndex,
    customerID: customerid,
    manager: manager,
    customerClientIDs: [],
    managerName: descriptiveName,
    customerNames: [],
    currencyCode: client.currencyCode,
  };

  // Initialize googleSelected and googleSelectedIds if not already initialized
  if (!this.googleSelected) {
    this.googleSelected = [];
  }
  if (!this.googleSelectedIds) {
    this.googleSelectedIds = [];
  }

  // Check if the client is already selected
  const index = this.googleSelected.findIndex(
    (selectedClient) => selectedClient === client.descriptiveName
  );

  if (index === -1) {
    // Client is not selected, so add to selections
    this.googleSelected.push(client.descriptiveName);
    this.googleSelectedIds.push(client.id);
  } else {
    // Client is selected, so remove from selections
    this.googleSelected.splice(index, 1);
    this.googleSelectedIds.splice(index, 1);
  }

  // Update dataform with selected client information
  this.dataform.customerClientIDs = [];
  this.dataform.customerNames = [];
  for (let i = 0; i < this.googleSelected.length; i++) {
    if (this.googleSelected[i]) {
      this.dataform.customerClientIDs.push(this.googleSelectedIds[i]);
      this.dataform.customerNames.push(this.googleSelected[i]);
    }
  }

  // Log the updated dataform
  console.log(this.dataform, "whole");
},

    submitData() {
      this.$emit("saveAdsCustomerID", this.dataform);
    },
    toggleCustomerSelectorModal() {
      this.$emit("toggleAdsCustomerSelectorModal", false);
      setTimeout(() => {
        this.searchCustomer = "";
      }, 2000);
    },
    selectGoogleCustomerAccount(customerdData) {
      this.$emit("saveAdsCustomerID", customerdData);
    },
  },
};
</script>

<style lang="scss">
.card.strike-through .collapse-title {
  text-decoration: line-through;
}
.search-option {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 10px;
  width: 100% !important;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.mainContainer {
  width: 100%;
}
.justify-content-end {
  justify-content: center !important;
}
.center-text-no-found{
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%; /* optional, if you want to center vertically */
}
</style>
